<template>
  <div class="t-container">
      <div class="t-accounts-msg-container">
          <h1 class="total-accounts-header-msg">
              Have an unpaid invoice?
          </h1>
      </div>
      <div class="t-accounts-box">
          <br>
          <div class="account_msg">Loading...</div>
          <br>
          <section action="" method="post" class="load">
              <input type="text" name="invoice-number" id="invoice_number" class="invoice_inputs">
              <br>
              <div class="invoice_inputs-btn" @click="search">Search</div>
              <br>
          </section>
          <div class="t-accounts-display">
              <div class="client-name"></div>
              <div class="client-invoice"></div>
              <div class="client-since"></div>
              <div class="client-o"></div>
              <div class="client-isPaid"></div>
          </div>
      </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
    mounted(){
        
        this.renderWorkAnimationLoadUp();
        this.detectMobileDevice();
        this.loadScript();
        
    },
    methods:{
        detectMobileDevice: function(){
               try{
                   if(document.createEvent("TouchEvent")) {
                       document.querySelector(".total-header-msg").style.paddingTop = "calc(17px*0.9)";
                       document.querySelector(".t-inner-container").style.paddingTop = "0px";
                   }
                } catch(e){ 
                    return false; 
                }
        },
        renderWorkAnimationLoadUp:function() {
            document.documentElement.style.setProperty('--mobilePaint', "#fff");
            gsap.from('.total-accounts-header-msg', {y: -200, duration: 1.27})
            document.querySelectorAll(".t-section a").forEach((attr)=>attr.style.color="#FFF");
            document.querySelector("body").style.backgroundColor = "#353B48";
            document.querySelector(".t-header").style.backgroundColor = document.querySelector('body').style.backgroundColor;
        },
        loadScript: function(){
            const TILDA_POST = document.createElement("script");
            TILDA_POST.src = "https://www.tildalabs.dev/tools/utilities.js";
            TILDA_POST.async = true;
            document.head.appendChild(TILDA_POST);

            TILDA_POST.onload = TILDA_POST.onreadystatechange = function(){
                document.querySelector("section").classList.remove("load");
                document.querySelector(".account_msg").innerText = "Enter Invoice Number";
                document.querySelector(".invoice_inputs").style.display= "block";
                document.querySelector(".invoice_inputs-btn").style.display= "flex";
            }
        },
        search:function(){
            if(document.querySelector("#invoice_number").value.length < 7) return;
            //Load
            document.querySelector("section").classList.add("load");
            document.querySelector(".account_msg").innerText = "Loading...";
            document.querySelector(".invoice_inputs").style.display= "none";
            document.querySelector(".invoice_inputs-btn").style.display= "none";
            let loadPDF = this.loadPDF;
            let renderInfo = this.renderInfo;
            window.Tilda.post({
                url:"https://www.tildalabs.dev/php/trie.php",
                data:{invoice: document.querySelector("#invoice_number").value, type:"search"},
                success:function(data) {
                    document.querySelector("section").classList.remove("load");
                    document.querySelector(".account_msg").innerText = "Results";
                    //document.querySelector(".t-accounts-display").innerHTML = datum.client;
                    let datum = JSON.parse(data);
                    loadPDF(datum.pdf);
                    renderInfo(datum)

                },
                reject: function (err) {
                    console.log(err);
                }
            });
            
        },
        renderInfo: function(payload) {
            document.querySelector(".client-name").innerHTML = "Name: " + payload.client;
            document.querySelector(".client-invoice").innerHTML = "Invoice #: " + payload.invoice_num;
            document.querySelector(".client-since").innerHTML = "Date: " + payload.since;
            document.querySelector(".client-o").innerHTML = "Amount: " + payload.owed;
            document.querySelector(".client-isPaid").innerHTML = payload.isPaid == 1 ? "Paid: fullfilled":"Paid: unpaid";
            this.loadPay(payload);
        },
        loadPDF:function(base64){
            var obj = document.createElement('object');
            obj.style.width = '100%';
            obj.style.height = '400px';
            obj.type = 'application/pdf';
            obj.data = 'data:application/pdf;base64,' + base64;
            document.querySelector(".t-accounts-display").appendChild(obj);
            
        },
        loadPay:function(payload) {
            var obj = document.createElement('button');
            obj.innerText = "Pay Now!";
            let boundries = this.boundries;
            obj.addEventListener('click',function() {
                boundries(payload);
            },false);
            if (payload.isPaid != 1) {
                document.querySelector(".t-accounts-display").appendChild(obj);
                document.querySelector(".t-accounts-display").scrollIntoView();
            }
            
        },
        boundries: function(amount){ 
            this.$router.push({
                name: 'Payment', 
                params: { o:  amount.owed*100, invoice: amount.invoice_num}
            });
            
        },
    },


}

</script>

<style lang="scss">
@font-face {
  font-family: "LotaGrotesque";
  src: url('./../assets/Lota-Grotesque-Regular.woff');
}
@font-face {
  font-family: "LotaGrotesque-Thin";
  src: url('./../assets/Lota-Grotesque-Thin.woff');
}
.t-accounts-msg-container{
    color: #fff;
    max-width:1280px;
    font-size: 72px;
    font-family: LotaGrotesque;
    margin: 0 auto;
}
.total-accounts-header-msg{
    padding-top: calc(90px*0.9);
    width: 70%;
    color: #fff;
    font-size: 72px;
    font-family: LotaGrotesque;
    text-align:initial;
}
.t-accounts-box{
    max-width: 500px;
    background-color: #353B48;
    border:1px solid #707070 ;
    margin: 0 auto;
    margin-bottom: 29px;
    .account_msg{
        color:#fff;
        font-family: LotaGrotesque;
    }
    section{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        max-width:65%;
        margin: 0 auto;
        .invoice_inputs{
            display: none;
        }
        input[type=text]{
            height:32px;
            background-color: #959595;
            border:none;
            opacity: .3;
        }
        .invoice_inputs-btn{
            border:none;
            height:27px;
            width:30%;
            margin: 0 auto;
            border-radius: 9px;
            background-color: #718093;
            color:#fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-family: LotaGrotesque-Thin;
        }
    }
}
.t-accounts-display{
    font-family: LotaGrotesque;
    text-align: start;
    color:#fff;
    button{
        width:100%;
        height: 40px;
        background-color: white;
        color: black;
        border: 2px solid #555555;
        cursor: pointer;
        transition-duration: 0.4s;
    }
    button:hover {
        background-color: #555555;
        color: white;
    }
}

@keyframes load {
  to {transform: rotate(360deg);}
}
 
.load:before {
  content: '';
  box-sizing: border-box;
  width: 70px;
  height: 70px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: load .6s linear infinite;
  margin: 0 auto;
}
@media screen and (max-width: 600px) {
    .total-accounts-header-msg{
        padding-top: 10px;
        font-size: 19px;
    }
    .t-inner-container h2{
        font-size: 2em;
        
    }
    .t-accounts-box{
        margin-top:34%;
    }
    video {
        width: 100%;
        height: 20vh;
    }
}
</style>